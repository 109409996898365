import { useContext } from "react";
import Header from "../Header/Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardUser } from '@fortawesome/free-solid-svg-icons';
import UIContext from "../Context/UiContext";


function TelDet() {

    setmInpShow(true);
    const { setmInpShow } = useContext(UIContext);

    return (
        <>
            <Header />
            <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 h-screen overflow-y-auto scroll-design'>
                <div className="md:col-span-7 bg-white md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
                    <section>
                        <span className='inline-block  place-content-center mr-4 text-blue-800 text-lg ml-2'><FontAwesomeIcon icon={faClipboardUser} /></span>
                        <h5 className="inline-flex items-center">استعلام تلگرام</h5>
                        <p className=' px-8 text-sm md:text-base'>
                            برای استعلام اطلاعات کاربر در تلگرام از این صفحه استفاده کنید.
                        </p>
                    </section>
                </div>
            </div>
        </>
    )

}

export default TelDet
