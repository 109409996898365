import { useState, useEffect } from "react";
import Header from "../Header/Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft, faXmark, faTrash, faPhone } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from 'react-paginate';
import { axiosPrivate , isFeatureEnabled } from "../Api/axios";
import contactImg from '../../img/contact.svg';
import { faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { useForm, Controller } from "react-hook-form";
import moment from 'moment-jalaali';
import DatePicker from 'react-multi-date-picker';
import '../../CSS/pagination.css';
import './input.css';
// import 'css/persian.datepicker.css'; import $ from 'jquery'; import 'js/persian.date'; import 'js/persian.datepicker';
// import DatePicker2 from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import Calender from "../number page/calender";
function InpHistory() {

    const [loading, setLoading] = useState(false);
    const [numList, setNumList] = useState([]);
    const [inpKind, setInpKind] = useState("id");
    const [fullBio, setFullBio] = useState();
    const [openBio, setOpenBio] = useState(false);
    const [hisKind, setHisKind] = useState("insta");
    const [showModal, setShowModal] = useState(false);  // State for modal visibility
    const [inputValue, setInputValue] = useState('');   // State for user input
    const [dlKind, setDlKind] = useState('instagram');   // Kind of input
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const [StartDate2, setStartDate2] = useState('');
    const [EndDate2, setEndDate2] = useState('');
    const { control, handleSubmit, formState: { errors } } = useForm();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredNumList, setFilteredNumList] = useState(numList);
    const [isMobile, setIsMobile] = useState(false);
    
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        handleResize(); // برای بار اول
        window.addEventListener('resize', handleResize); // برای تغییر سایز
        return () => window.removeEventListener('resize', handleResize); // پاکسازی
    }, []);
    
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;


    // Handle search input change
    const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    
    // Filter the list based on the phone number search
    const filteredList = numList.filter(item => item.mobile.includes(query));
    setFilteredNumList(filteredList);
  };
  
    const handleOpenbio = () => {
        setOpenBio(true);
    }
    const handleClosebio = () => {
        setOpenBio(false)
    };
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getList = async () => {
            try {
                setLoading(true);
                const response = await axiosPrivate.get(`/list/mobile`, {
                    signal: controller.signal
                });
                if (isMounted) {
                    setNumList(response?.data?.slice(0).reverse());
                    setFilteredNumList(response?.data?.slice(0).reverse()); // Initialize filtered list
                    setTotalPages(Math.ceil(response?.data?.length /  itemsPerPage));
                }


                setLoading(false);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }
        
        getList();
        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [])

    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10;

    useEffect(() => {
        if (numList && numList.length > 0) {
            setTotalPages(Math.ceil(numList.length / itemsPerPage));
        } else {
            setTotalPages(0); 
        }
    }, [numList]);
    const handlePageChange = ({ selected }) => {
        setCurrentPage(Math.min(Math.max(0, selected), totalPages - 1));
    };
     const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const subset = searchQuery 
  ? filteredNumList.slice(startIndex, endIndex)  // Use filtered list when there is a search query
  : numList.slice(startIndex, endIndex);         // Otherwise, use the full list

  useEffect(() => { 
    if (startDate && endDate){
        const filtered = numList.filter(item => { const itemDate = moment(item.created_at, 'jYYYY-jMM-jDD HH:mm:ss');
        return itemDate.isBetween(startDate, endDate, null, '[]'); }); 
        setFilteredNumList(filtered); 
        setTotalPages(Math.ceil(filtered.length / itemsPerPage));
     } 
        else { 
            setFilteredNumList(numList); 
            setTotalPages(Math.ceil(numList.length / itemsPerPage)); 
    } 
    }, [startDate, endDate, numList]);



    const handleDelete = async (input) => {
        try {
            const response = await axiosPrivate.post('/remove/mobile', { input });
            if (response.status === 200) {
                setNumList((prevList) => prevList.filter(item => item.mobile !== input));
            }
        } catch (err) {
            console.error("Error deleting item:", err);
        }
    };
    //دانلود تمام اطلاعات
    const handleExportAll = async () => {
        try {
            const formData = new FormData();
            formData.append("all", true);
            const exportResponse  = await  axiosPrivate.post(`/export/${dlKind}`, formData, {
            });
            console.log("Export Response:", exportResponse .data);
            const fileUrl = exportResponse?.data?.file;
            if (fileUrl) {
                const link = document.createElement('a');
                link.href = fileUrl;
                link.setAttribute('download', 'instagram_records.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                console.error("File URL not found in export response.");
                alert("No records found for the export request.");
            }
        } catch (error) {
            console.error("Export Error:", error);
            alert("An error occurred while exporting data. Please try again.");
        }
        finally {
            setShowModal(false);
        }
    };
 
    const handleDownload = async () => {
        try {
            setLoading(true);
             if (!inputValue && (!dateStart || !dateEnd)) {
                alert(".لطفا یک فیلد را تکمیل کنید");
                setLoading(false);
                return;
            }
             const startDate = dateStart ? moment(dateStart.toDate()).format('YYYY/MM/DD') : null;
             const endDate = dateEnd ? moment(dateEnd.toDate()).format('YYYY/MM/DD') : null; 
            
            const requestBody = {
                count: inputValue && !isNaN(Number(inputValue)) ? Number(inputValue) : null,
                date_start: startDate,
                date_end: endDate
            };
            console.log("Request Body:", requestBody);
            const response = await axiosPrivate.post(`/export/${dlKind}`, requestBody);
            console.log("Backend Response:", response.data);
            const fileUrl = response?.data?.file;
            if (fileUrl) {
                const link = document.createElement('a');
                link.href = fileUrl;
                link.setAttribute('download', 'record.xlsx'); 
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                console.error("File URL not found in response.");
            }
        } catch (err) {
            console.error("Error:", err);
        }
       
        finally {
            setLoading(false);
            setShowModal(false);
        }
    };
    
    return (
        <>
            <Header />
                <section className="dark:bg-stone-900 dark:text-gray-100 transition-all duration-100 ease-in h-screen">
                    <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 overflow-x-hidden'>
                        <div className="md:col-span-7 md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
                            <span className='inline-block place-content-center mr-4 text-green-800 dark:text-green-200 text-lg ml-2'><FontAwesomeIcon icon={faClockRotateLeft} /></span>
                                <h5 className="inline-flex items-start">تاریخچه استعلام</h5>
                                <div className={isMobile ? "flex-wrap justify-between gap-2 mt-2 mr-2" : "flex justify-between mt-2"}>
                                    <div className="flex items-center px-8">
                                    <ul className={`list-inline ${isMobile ? "d-flex flex-wrap gap-2" : "d-flex flex-wrap gap-1"}`}>
                                            <li onClick={() => { setHisKind("insta"); setCurrentPage(0); setDlKind("instagram") }} className={`p-2 flex justify-center items-center hover:bg-yellow text-white text-sm ${hisKind === "insta" ? "bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`}>
                                                <span>اینستاگرام</span>
                                                <span className='d-flex justify-center items-center text-white dark:text-blue-300 text-lg ml-1'>
                                                    <FontAwesomeIcon icon={faInstagram} />
                                                </span>
                                            </li>
                                            <li onClick={() => { setHisKind("whatsapp"); setCurrentPage(0); setDlKind("whatsapp") }} className={`p-2 flex justify-center items-center hover:bg-yellow text-white text-sm ${hisKind === "whatsapp" ? "bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`}>
                                                <span>واتساپ</span>
                                                <span className='d-flex justify-center items-center text-white dark:text-blue-300 text-lg ml-1'>
                                                    <FontAwesomeIcon icon={faWhatsapp} />
                                                </span>
                                            </li>
                                            
                                            <li onClick={() => { setHisKind("caller"); setCurrentPage(0); setDlKind("caller") }} className={`p-2 flex justify-center items-center hover:bg-yellow text-white text-sm ${hisKind === "caller" ? "bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`}>
                                                <span>هویت تلفنی</span>
                                                <span className='d-flex justify-center items-center text-white dark:text-blue-300 text-lg ml-1'>
                                                    <FontAwesomeIcon icon={faPhone} />
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                     <div className="flex items-center">
                                    <div className="search-container ltr flex">
                                        <input 
                                                className="input" 
                                                type="tel" 
                                                placeholder="جستجو بر اساس شماره" 
                                                value={searchQuery} 
                                                onChange={handleSearchChange} 
                                            />
                                            <svg viewBox="0 0 24 24" className="search__icon">
                                            <g>
                                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                                            </g>
                                            </svg>
                                            
                                    </div>
                                    {/* <Calender /> */}
                                    {/* <div className="date-picker-container"> <DatePicker2 selectsRange={true} startDate={startDate} endDate={endDate} onChange={(update) => { setDateRange(update); }} isClearable={true} dateFormat="yyyy-MM-dd" placeholderText="Select a date range" /> </div> */}
                                            {/* <div className="flex ">
                                            <div className="date-picker-container">
                                            <input type="date" className="range-from-example-alt" placeholder="تاریخ شروع" />
                                            <input type="date" className="range-to-example-alt" placeholder="تاریخ پایان" />
                                                {/* <DatePicker2 selected={StartDate2} onChange={date => setStartDate2(date)} placeholderText="تاریخ شروع" />
                                                <DatePicker2 selected={EndDate2} onChange={date => setEndDate2(date)} placeholderText="تاریخ پایان" /> 
                                            </div></div> */}
                                   
                                    <button
                                        onClick={() => setShowModal(true)}
                                        className="py-1 px-2 hover:bg-yellow text-white text-base ml-1 bg-green-500 rounded select-none transition-all duration-200 ease-in"
                                    >
                                        دانلود خروجی
                                    </button>
                                    
                                    {showModal && (<div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
                                                        <div className="bg-white p-4 rounded w-96 shadow-lg">
                                                            <form onSubmit={handleSubmit(handleDownload)} >
                                                                <h2 className="text-lg font-bold mb-4">چند رکورد آخر دانلود شود؟</h2>
                                                                    <input
                                                                            type="number"
                                                                            value={inputValue}
                                                                            id='num'
                                                                            onChange={(e) => setInputValue(e.target.value)}
                                                                            className="border border-gray-300 p-2 rounded mb-4 w-full"
                                                                            placeholder="تعداد رکورد"
                                                                    />
                                                                    <span className="block mb-1">تاریخ شروع:</span>
                                                                    <Controller
                                                                            control={control}
                                                                            name="dateStart"
                                                                            render={({ field }) => (
                                                                                <DatePicker
                                                                                placeholder="تاریخ شروع را انتخاب کنید"
                                                                                value={dateStart}
                                                                                id='date1'
                                                                                onChange={(date) => {
                                                                                        setDateStart(date);
                                                                                        field.onChange(date);
                                                                                }}
                                                                                format={"YYYY/MM/DD"}
                                                                                calendar={persian}
                                                                                locale={persian_fa}
                                                                                calendarPosition="bottom-right"
                                                                                />
                                                                            )}
                                                                            />
                                                                    <span className="block mb-1">تاریخ پایان:</span>
                                                                            <Controller
                                                                                control={control}
                                                                                name="dateEnd"
                                                                                render={({ field }) => (
                                                                                        <DatePicker
                                                                                        id='date2'
                                                                                        placeholder="تاریخ پایان را انتخاب کنید"
                                                                                        value={dateEnd}
                                                                                        onChange={(date) => {
                                                                                            setDateEnd(date);
                                                                                            field.onChange(date);
                                                                                        }}
                                                                                        format={"YYYY/MM/DD"}
                                                                                        calendar={persian}
                                                                                        locale={persian_fa}
                                                                                        calendarPosition="bottom-left"
                                                                                        />
                                                                                )}
                                                                            />
                                                                        <div className="flex justify-center">            
                                                                        <button type="submit" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ml-2">دانلود </button>
                                                                        <button
                                                                            onClick={() => setShowModal(false)}
                                                                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" >
                                                                            انصراف</button>
                                                                        </div>
                                                                    </form>
                                                                         <div className="flex justify-center">
                                                                        <button onClick={handleExportAll} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-1">
                                                                                دانلود تمام اطلاعات
                                                                        </button>
                                                                        </div>
                                                                        </div>
                                                                        </div> )}
                                                                        <div className='pl-8 flex justify-end'>
                                                        {
                                                            !loading && totalPages > 0 && 
                                                        
                                                            <div className={isMobile ? 'pagination-footer' : 'pagination-container'}>
                                                                <ReactPaginate
                                                                    pageCount={totalPages}
                                                                    onPageChange={handlePageChange}
                                                                    forcePage={Math.min(currentPage, totalPages - 1)}
                                                                    previousLabel={"<<"}
                                                                    nextLabel={">>"}
                                                                    breakLabel={"..."}
                                                                    pageRangeDisplayed={1}
                                                                    marginPagesDisplayed={1}
                                                                    containerClassName={"pagination-container"}
                                                                    pageLinkClassName={"page-link"}
                                                                    activeLinkClassName={"active"}
                                                                    previousLinkClassName={"page-link"}
                                                                    nextLinkClassName={"page-link"}
                                                                    disabledClassName={"disabled"}
                                                                    breakLinkClassName={"page-link"}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                  
                                    </div>
                                 </div>
                        {
                            hisKind === "insta" &&
                            <div className="news-card grid grid-cols-1 gap-x-4 mt-2 rounded-md px-2 inner-scroll-250 overflow-y-auto">
                                {loading ? <div className='flex'>< p className=' text-right text-lg mt-2 pr-6' > در حال بارگذاری...</p></div>
                                    : subset?.map((log, i) => (
                                        // log.mobile &&
                                        <div key={log.id || i} className="relative w-full bg-gray-50 dark:bg-gray-600 hover:bg-blue-200 transition-all duration-200 ease-in border border-slate-400 rounded-md mb-3 p-3">
                                            <div className="news-card-header col-span-3">
                                                <div className='flex md:flex-col lg:flex-row justify-between'>
                                                    <div className='flex justify-between items-center mr-6 w-full'>
                                                        <div className='flex flex-col justify-between'>
                                                            <div className="flex">
                                                                <div className="md:ml-4">
                                                                    <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                        شماره تماس:
                                                                        {
                                                                            log?.mobile ? <span> {log?.mobile}+ </span>
                                                                                : log?.status === 2 ? <span> ندارد </span>
                                                                                    : log?.status === 1 ? <span>...</span>
                                                                                        : "..."
                                                                        }
                                                                    </p>
                                                                    
                                                                    <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                        نام کاربری:
                                                                        {
                                                                            log?.username ? <span> {log?.username} </span>
                                                                                : log?.status === 2 ? <span> ندارد </span>
                                                                                    : log?.status === 1 ? <span>...</span>
                                                                                        : "..."
                                                                        }
                                                                    </p>

                                                                    <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                        شناسه کاربری:
                                                                        {
                                                                            log?.user_id ? <span> {log?.user_id} </span>
                                                                                : log?.status === 2 ? <span> ندارد </span>
                                                                                    : log?.status === 1 ? <span>...</span>
                                                                                        : "..."
                                                                        }
                                                                    </p>
                                                                    {/* <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                    نام و نام خانوادگی‌:
                                                                    {
                                                                        log?.name ? <span>{log?.name}</span>
                                                                            // : log?.status === 3 ? <span> ندارد </span>
                                                                            : log?.status === 2 ? <span> ندارد </span>
                                                                                : log?.status === 1 ? <span>...</span>
                                                                                    : "..."
                                                                    }
                                                                </p> */}
                                                                    <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                        نوع اکانت: {log?.public === 1 && "Public"}
                                                                        {log?.public === 0 && "Private"}
                                                                        {log?.public === null && "..."}
                                                                    </p>
                                                                    <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">
                                                                        فالوئر:
                                                                        {
                                                                            log?.follower_count ? <span>{log?.follower_count}</span>
                                                                                : log?.status === 2 ? <span> ندارد </span>
                                                                                    : log?.status === 1 ? <span>...</span>
                                                                                        : "..."
                                                                        } | فالویینگ:
                                                                        {
                                                                            log?.following_count ? <span>{log?.following_count}</span>
                                                                                : log?.status === 2 ? <span> ندارد </span>
                                                                                    : log?.status === 1 ? <span>...</span>
                                                                                        : "..."
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="">
                                                                    <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white bio-holder-his">
                                                                        بیوگرافی:
                                                                        {log?.biography === null ? ' ندارد '
                                                                            : <span className=" cursor-pointer select-none" onClick={() => { handleOpenbio(); setFullBio(log?.biography) }}>{`${log?.biography?.length > 50
                                                                                ? `...${log?.biography?.slice(0, 20)}`
                                                                                : log?.biography}`}</span>}
                                                                        {openBio &&
                                                                            <div className='bioWrap'>
                                                                                <div className='fullScreenImage'>
                                                                                    <p className="text-center text-white">
                                                                                        <div className="flex justify-between border-b border-dark-blue">
                                                                                            <p className='flex justify-between text-lg text-white m-0'><span>بیوگرافی‌ کامل</span></p>
                                                                                            <span className=" text-white hover:opacity-70"><FontAwesomeIcon icon={faXmark} className=' cursor-pointer select-none' onClick={handleClosebio} /></span>
                                                                                        </div>
                                                                                        <p className=' overflow-y-auto text-white text-lg p-6 h-4/5'>{fullBio}</p>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </p>
                                                                    <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">تاریخ ثبت:  
                                                                        {log?.created_at ? <span className="inline-block" style={{ "direction": "rtl" }}>{log?.created_at}</span> : " نامشخص "}
                                                                    </p>
                                                                    <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">
                                                                        آخرین آپدیت:
                                                                        {
                                                                            log?.updated_at ? <span className="inline-block" style={{ "direction": "rtl" }}> {log?.updated_at} </span> : " نامشخص "
                                                                        }
                                                                    </p>
                                                                    <p className={`m-0 mt-1 text-sm text-gray-600 dark:text-white`}>
                                                                        وضعیت استعلام: <span className={`${(
                                                                            (log?.status === 0) ? 'text-gray-500 font-bold' :
                                                                                (log?.status === 1) ? 'text-blue-500 font-bold' :
                                                                                    (log?.status === 2) ? 'text-red-500 font-bold' :
                                                                                        (log?.status === 3) ? 'text-green-500 font-bold' : ""
                                                                        )
                                                                            }`}>{
                                                                                (log?.status === 0 ? 'در انتظار' :
                                                                                    log?.status === 1 ? 'در حال استخراج اطلاعات' :
                                                                                        log?.status === 2 ? 'دارای شبکه اجتماعی نیست' :
                                                                                            log?.status === 3 ? 'کامل' : "ورودی نامشخص")
                                                                            }</span>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col items-center justify-center">
                                                            <div className="">
                                                                {
                                                                    log?.profile_image ? (<a href={log?.profile_image} download target="_blank" rel="noopener noreferrer" ><img src={`${log.profile_image}?${new Date().getTime(1000)}`} alt="تصویر پروفایل" width={100} className="rounded-full"  onError={(e) => {
                                                                        e.target.onerror = null; 
                                                                        e.target.src = contactImg;
                                                                    }} /></a>
                                                                ) : <img src={contactImg} alt="تصویر پروفایل" width={100} className="rounded-full" />
                                                                }
                                                            </div>
                                                            {
                                                                <div className="flex items-center justify-center gap-1">
                                                                    {log?.username &&
                                                                        <div className="mt-2">
                                                                            <a href={`https://www.instagram.com/${log?.username}/`} target="_blank">
                                                                                <button className={`py-1 px-2 w-28 hover:bg-yellow text-white text-base bg-green-500 rounded select-none transition-all duration-200 ease-in`}>مشاهده اکانت</button>
                                                                            </a>
                                                                        </div>
                                                                    }
                                                                    {/* Delete button */}
                                                                    <button className={`flex justify-center items-center py-1 px-2 mt-2 w-28 hover:bg-red-800 text-base text-white bg-red-500 rounded select-none transition-all duration-200 ease-in`} onClick={() => handleDelete(log?.mobile)}>
                                                                        <span>حذف</span>
                                                                        <span className="text-xs ml-1"><FontAwesomeIcon icon={faTrash} /></span>
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div>

                                                    </div>
                                                    {/* <div className=' w-1/3 flex flex-col items-center justify-center'>
                                                <p className="m-0 text-sm text-right mt-1 text-gray-600 dark:text-white">
                                                    تاریخ: {log?.date}
                                                </p>
                                                <p className="m-0 text-sm text-right mt-1 text-gray-600 dark:text-white">
                                                    ساعت: {log?.time?.slice(0, 8)}
                                                </p>
                                            </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        }
                        {
                            hisKind === "whatsapp" &&
                            <div className="news-card grid grid-cols-1 gap-x-4 mt-2 rounded-md px-2 inner-scroll-250 overflow-y-auto">
                                {loading ? <div className='flex'>< p className=' text-right text-lg mt-2 pr-6' > در حال بارگذاری...</p></div>
                                    : subset?.map((log, i) => (
                                        log?.whatsapp_status === 1 ?
                                            <div key={i} className="w-full bg-gray-50 dark:bg-gray-600 hover:bg-blue-200 transition-all duration-200 ease-in border border-slate-400 rounded-md mb-3 p-3">
                                                <div className="news-card-header col-span-3">
                                                    <div className='flex md:flex-col lg:flex-row justify-between'>
                                                        <div className='flex justify-between items-center mr-6 w-full'>
                                                            <div className='flex flex-col justify-between'>
                                                                <div className="flex">
                                                                    <div className="md:ml-4">
                                                                        <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                            شماره تماس:
                                                                            {
                                                                                log?.whatsapp[0]?.mobile && <span> {log?.whatsapp[0]?.mobile}+ </span>
                                                                            }
                                                                        </p>
                                                                        <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">کشور:
                                                                            {log?.country_name ? <span> {log?.country_name} </span> : " نامشخص "}
                                                                        </p>
                                                                        {/* <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                    شناسه کاربری:
                                                                    {
                                                                        log?.user_id ? <span>{log?.user_id}</span>
                                                                            : log?.status === 2 ? <span> ندارد </span>
                                                                                : log?.status === 1 ? <span>...</span>
                                                                                    : "..."
                                                                    }
                                                                </p> */}
                                                                        <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                            نوع اکانت: {log?.whatsapp[0]?.isBusiness === 1 && " بیزینس "}
                                                                            {log?.whatsapp[0]?.isBusiness !== 1 && " شخصی "}
                                                                        </p>
                                                                        <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white bio-holder-his">
                                                                            {
                                                                                log?.whatsapp[0]?.biography ?
                                                                                    <>
                                                                                        درباره:
                                                                                        <span className=" cursor-pointer select-none" onClick={() => { handleOpenbio(); setFullBio(log?.whatsapp[0]?.biography) }}>{`${log?.whatsapp[0]?.biography?.length > 50
                                                                                            ? `
                                        ...${log?.whatsapp[0]?.biography?.slice(0, 20)} `
                                                                                            : log?.whatsapp[0]?.biography} `}</span>
                                                                                    </> : <>درباره: <span className="text-red-500 font-bold"> ندارد </span></>
                                                                            }
                                                                            {openBio &&
                                                                                <div className='bioWrap'>
                                                                                    <div className='fullScreenImage'>
                                                                                        <p className="text-center text-white">
                                                                                            <div className="flex justify-between border-b border-dark-blue">
                                                                                                <p className='flex justify-between text-lg text-white m-0'><span>بیوگرافی‌ کامل</span></p>
                                                                                                <span className=" text-white hover:opacity-70"><FontAwesomeIcon icon={faXmark} className=' cursor-pointer select-none' onClick={handleClosebio} /></span>
                                                                                            </div>
                                                                                            <p className=' overflow-y-auto text-white text-lg p-6 h-4/5'>{fullBio}</p>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                            {
                                                                                log?.whatsapp[0]?.name &&
                                                                                <> نام کاربری:
                                                                                    <span> {log?.whatsapp[0]?.name} </span></>
                                                                            }
                                                                        </p>
                                                                        <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">تاریخ ثبت:
                                                                            {log?.created_at ? <span className="inline-block" style={{ "direction": "rtl" }}> {log?.created_at} </span> : " نامشخص "}
                                                                        </p>
                                                                        <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">
                                                                            آخرین آپدیت:
                                                                            {
                                                                                log?.updated_at ? <span className="inline-block" style={{ "direction": "rtl" }}> {log?.updated_at} </span> : " نامشخص "
                                                                            }
                                                                        </p>
                                                                        <p className={`m-0 mt-1 text-sm text-gray-600 dark:text-white`}>
                                                                            وضعیت استعلام: <span className={`${(
                                                                                (log?.whatsapp_status === 2) ? 'text-red-500 font-bold' :
                                                                                    (log?.whatsapp_status === 1) ? 'text-green-500 font-bold' : ""
                                                                            )
                                                                                }`}>{
                                                                                    (
                                                                                        log?.whatsapp_status === 1 ? 'واتساپ دارد' :
                                                                                            'واتساپ ندارد')
                                                                                }</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-col items-center">
                                                                {<div className="">
                                                                  {
                                                                        (log?.whatsapp_status === 1 && log?.whatsapp[0]?.profile_image) ? (
                                                                            <a href={log?.whatsapp[0]?.profile_image} download target="_blank" rel="noopener noreferrer">
                                                                                <img
                                                                                    src={log?.whatsapp[0]?.profile_image}
                                                                                    alt="تصویر پروفایل"
                                                                                    width={100}
                                                                                    className="rounded-full"
                                                                                    onError={(e) => {
                                                                                        e.target.onerror = null; // جلوگیری از حلقه بی‌پایان
                                                                                        e.target.src = contactImg; // جایگزینی تصویر پیش‌فرض
                                                                                    }}
                                                                                />
                                                                            </a>
                                                                        ) : (
                                                                            <img
                                                                                src={contactImg}
                                                                                alt="تصویر پیش‌فرض"
                                                                                width={100}
                                                                                className="rounded-full"
                                                                            />
                                                                        )
                                                                    }
                                                                </div>
                                                                }
                                                                <div className="flex items-left  justify-center gap-1">
                                                                    {/* Delete button */}
                                                                    <button className={`py-1 px-2 mt-2 w-28 hover:bg-red-800 text-base text-white bg-red-500 rounded select-none transition-all duration-200 ease-in`} onClick={() => handleDelete(log?.mobile)}><span className="text align center">حذف</span><span className="text-xs ml-1"><FontAwesomeIcon icon={faTrash} /></span></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className=' w-1/3 flex flex-col items-center justify-center'>
                                                <p className="m-0 text-sm text-right mt-1 text-gray-600 dark:text-white">
                                                    تاریخ: {log?.date}
                                                </p>
                                                <p className="m-0 text-sm text-right mt-1 text-gray-600 dark:text-white">
                                                    ساعت: {log?.time?.slice(0, 8)}
                                                </p>
                                            </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            : log?.whatsapp_status === 2 ? <div key={i} style={{ "minHeight": "134.2px" }} className="w-full bg-gray-50 dark:bg-gray-600 hover:bg-blue-200 transition-all duration-200 ease-in border border-slate-400 rounded-md mb-3 p-3">
                                                <div className="news-card-header col-span-3">
                                                    <div className='flex md:flex-col lg:flex-row justify-between'>
                                                        <div className='flex justify-between items-center mr-6 w-full'>
                                                            <div className='flex flex-col justify-between'>
                                                                <div className="flex">
                                                                    <div className="md:ml-4">
                                                                        <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                            شماره تماس:
                                                                            {
                                                                                log?.mobile ? <span> {log?.mobile}+ </span>
                                                                                    : log?.status === 2 ? <span> ندارد </span>
                                                                                        : log?.status === 1 ? <span>...</span>
                                                                                            : "..."
                                                                            }
                                                                        </p>
                                                                        <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">تاریخ ثبت:
                                                                            {log?.created_at ? <span className="inline-block" style={{ "direction": "rtl" }}> {log?.created_at} </span> : " نامشخص "}
                                                                        </p>
                                                                        <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">
                                                                            آخرین آپدیت:
                                                                            {
                                                                                log?.updated_at ? <span className="inline-block" style={{ "direction": "rtl" }}> {log?.updated_at} </span> : " نامشخص "
                                                                            }
                                                                        </p>
                                                                        <p className={`m-0 mt-1 text-sm text-gray-600 dark:text-white`}>
                                                                            وضعیت استعلام: <span className={`${(
                                                                                (log?.whatsapp_status === 2) ? 'text-red-500 font-bold' :
                                                                                    (log?.whatsapp_status === 1) ? 'text-green-500 font-bold' : ""
                                                                            )
                                                                                }`}>{
                                                                                    (
                                                                                        log?.whatsapp_status === 2 && <span> واتساپ ندارد </span>)
                                                                                }</span>
                                                                        </p>
                                                                    </div></div></div>
                                                            <div className="flex items-center justify-center gap-1">
                                                                {/* Delete button */}
                                                                <button
                                                            className={`flex items-center justify-center py-1 px-2 mt-2 w-28 hover:bg-red-800 text-base text-white bg-red-500 rounded select-none transition-all duration-200 ease-in`}
                                                            onClick={() => handleDelete(log?.mobile)}
                                                            >
                                                            <span>حذف</span>
                                                            <span className="text-xs ml-1 flex items-center">
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </span>
                                                            </button>

                                                            </div>
                                                        </div></div></div>
                                            </div> : <div key={i} style={{ "min-height": "134.2px" }} className="w-full bg-gray-50 dark:bg-gray-600 hover:bg-blue-200 transition-all duration-200 ease-in border border-slate-400 rounded-md mb-3 p-3">
                                                <div className="news-card-header col-span-3">
                                                    <div className='flex md:flex-col lg:flex-row justify-between'>
                                                        <div className='flex justify-between items-center mr-6 w-full'>
                                                            <div className='flex flex-col justify-between'>
                                                                <div className="flex">
                                                                    <div className="md:ml-4">
                                                                        <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                            شماره تماس:
                                                                            {
                                                                                log?.mobile ? <span> {log?.mobile} </span>
                                                                                    : log?.status === 2 ? <span> ندارد </span>
                                                                                        : log?.status === 1 ? <span>...</span>
                                                                                            : "..."
                                                                            }
                                                                        </p>
                                                                        <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">تاریخ ثبت:
                                                                            {log?.created_at ? <span className="inline-block" style={{ "direction": "rtl" }}> {log?.created_at} </span> : " نامشخص "}
                                                                        </p>
                                                                        <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">
                                                                            آخرین آپدیت:
                                                                            {
                                                                                log?.updated_at ? <span className="inline-block" style={{ "direction": "rtl" }}> {log?.updated_at} </span> : " نامشخص "
                                                                            }
                                                                        </p>
                                                                        <p className={`m-0 mt-1 text-sm text-gray-600 dark:text-white`}>
                                                                            وضعیت استعلام: <span className={`${(
                                                                                (log?.whatsapp_status === null) && 'text-blue-800 font-bold'
                                                                            )
                                                                                }`}>{
                                                                                    (
                                                                                        log?.whatsapp_status === null && <span> در حال استعلام </span>)
                                                                                }</span>
                                                                        </p>
                                                                    </div></div></div></div></div></div>
                                            </div>
                                    ))}
                            </div>
                        }
                        {
                            hisKind === "caller" && (
                                <div className="news-card grid grid-cols-1 gap-x-4 mt-2 rounded-md px-2 inner-scroll-250 overflow-y-auto">
                                {loading ? (
                                    <div className="flex">
                                    <p className="text-right text-lg mt-2 pr-6"> در حال بارگذاری...</p>
                                    </div>
                                ) : (
                                    subset.map((log, i) => (
                                    <div key={i} className="w-full bg-gray-50 dark:bg-gray-600 hover:bg-blue-200 transition-all duration-200 ease-in border border-slate-400 rounded-md mb-3 p-3">
                                        <div className="news-card-header col-span-3">
                                        <div className="flex md:flex-col lg:flex-row justify-between">
                                            <div className="flex justify-between items-center mr-6 w-full">
                                            <div className="flex flex-col justify-between">
                                                <div className="flex">
                                                <div className="md:ml-4">
                                                    <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                    شماره تماس:
                                                                            {
                                                                                log?.mobile ? <span> {log?.mobile} </span>
                                                                                    : log?.status === 2 ? <span> ندارد </span>
                                                                                        : log?.status === 1 ? <span>...</span>
                                                                                            : "..."
                                                                            }
                                                    </p>
                                                    <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                    نام کاربر: 
                                                    {log?.caller?.[0]?.name?.[0]?.name || " ناشناس "}
                                                    </p>
                                                    <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                    وضعیت: 
                                                    {log?.caller_status === 1 ? (
                                                        <span className="text-green-500 font-bold">
                                                        شماره یافت شد
                                                        </span>
                                                    ) : log?.caller_status === 2 ? (
                                                        <span className="text-red-500 font-bold">
                                                        شماره یافت نشد
                                                        </span>
                                                    ) : (<span className="text-blue-500 font-bold">
                                                       در حال استخراج اطلاعات
                                                        </span>)}
                                                    </p>
                                                    <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                    تاریخ ثبت: 
                                                    {log?.created_at ? <span className="inline-block" style={{ "direction": "rtl" }}>{log?.created_at}</span> : " نامشخص "}
                                                    </p>
                                                    <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                    آخرین آپدیت:
                                                    {
                                                                            log?.updated_at ? <span className="inline-block" style={{ "direction": "rtl" }}> {log?.updated_at} </span> : " نامشخص "
                                                                        }
                                                    </p>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-col items-center">
                                                <div className="flex items-left justify-center gap-1">
                                                <button
                                                    className={`py-1 px-2 mt-2 w-28 hover:bg-red-800 text-base text-white bg-red-500 rounded select-none transition-all duration-200 ease-in`}
                                                    onClick={() => handleDelete(log?.mobile)}
                                                >
                                                    <span>حذف</span>
                                                    <span className="text-xs ml-1">
                                                    <FontAwesomeIcon icon={faTrash} />
                                                    </span>
                                                </button>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    ))
                                )}
                                </div>
                            )
                            }
                    </div>
                </div>
            </section>
        </>
    )

}

export default InpHistory
