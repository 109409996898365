import { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCheckSquare, faEllipsis, faPhone, faXmark, faUser } from "@fortawesome/free-solid-svg-icons";
import UIContext from "./Context/UiContext";
import contactImgTel from '../img/sample-avatar-min.jpg';
import contactImginsta from '../img/hipster-avatar-image-vector.jpg';
import contactImgTw from '../img/contact.svg';
import contactImg2 from '../img/contact.svg';
import { faFacebook,faGithub, faInstagram, faMeta, faReddit, faTelegram, faTelegramPlane, faTiktok, faViber, faWhatsapp, faWordpress, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { NavLink } from "react-router-dom";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";

function Result() {

    const { setDetShow, setInstaCard, setShow, socialCh, setSocailCh, contactImg, setContactImg, setmInpShow, setAccShow, inqData, inq, inqShow, setInqShow, setInqData, accData, setAccData } = useContext(UIContext);
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tTipShow, setTTipShow] = useState(false);

    const handleOpenModal = () => {
        // setSlideNumber(i)
        setOpenModal(true)
    }

    const backToInq = () => {
        // setSlideNumber(i)
        setDetShow(true);
        setShow(false);
        setAccShow(true);
        setSocailCh('');
        setmInpShow(false);
        setInqShow(true);
        console.log(inqShow);
        setInstaCard(false);
        // setInqData([]);
        // setAccData([]);
    }

    // Close Modal
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const [openBio, setOpenBio] = useState(false);

    const handleOpenbio = () => {
        setOpenBio(true)
    }

    // Close Modal
    const handleClosebio = () => {
        setOpenBio(false)
    };

    return (
        <>
            {openModal &&
                <div className='sliderWrap'>
                    <FontAwesomeIcon icon={faXmark} className='btnClose' onClick={handleCloseModal} />
                    {/* <FontAwesomeIcon icon={faChevronLeft} className='btnPrev' onClick={prevSlide} />
                    <FontAwesomeIcon icon={faChevronRight} className='btnNext' onClick={nextSlide} /> */}
                    <div className='fullScreenImage'>
                    {
                    socialCh === "insta" ? (
                        accData?.profile_image ? (
                        <img src={accData.profile_image} alt="profile_instagram" />
                        ) : (
                        <img src={contactImg2} alt="sample_profile" />
                        )
                    ) : socialCh === "whatsapp" ? (
                        inqData?.whatsapp[0]?.profile_image ? (
                        <img src={inqData.whatsapp[0].profile_image} alt="profile_whatsapp" />
                        ) : (
                        <img src={contactImg2} alt="sample_profile" />
                        )
                    ) : socialCh === "caller" ? (
                        inqData?.whatsapp[0]?.profile_image ? (
                        <img src={inqData.whatsapp[0].profile_image} alt="profile_caller" />
                        ) : (
                        <img src={contactImg2} alt="sample_profile" />
                        )
                    ) : (
                        <img src={contactImg2} alt="sample_profile" />
                    )
                    }
                        <div className='gallery-caption max-w-full'>
                            {/* <p className="text-center text-white m-0 text-xs mt-1">
                                <span>{data[slideNumber].path}</span><span>{data[slideNumber].date} - {data[slideNumber].time}</span><span>{data[slideNumber].size}</span>
                            </p> */}
                        </div>
                    </div>
                </div>
            }
            <span className='inline-block mr-4 mt-4 text-green-800 text-lg ml-2'><FontAwesomeIcon icon={faCheckSquare} /></span>
            <h5 className="inline-flex items-center">نتیجه استعلام</h5>
            <p className=' px-8'>
                نتیجه استعلام مورد نظر شما به شرح زیر است.
            </p>
            <section className="card-con">
                <div className="card1">
                    <div className="card">
                        <div className="absolute right-3 top-3 w-10 h-10 z-50 flex justify-end">
                            <button className=" p-2 rounded text-white hover:text-middle-yellow ransition-all duration-200 ease-in" onClick={backToInq}><span className='text-xl text-white hover:text-middle-yellow'><FontAwesomeIcon icon={faArrowRight} /></span></button>
                        </div>
                        <div className="left-container relative">
                        {
                                socialCh === "insta" ? (
                                    accData?.profile_image ? (
                                    <img
                                        src={accData.profile_image}
                                        alt="Profile Image"
                                        className="hover:opacity-80 cursor-pointer"
                                        onClick={() => handleOpenModal()}
                                    />
                                    ) : (
                                    <img
                                        src={contactImg2}
                                        alt="Profile Image"
                                        className="hover:opacity-80 cursor-pointer"
                                        onClick={() => handleOpenModal()}
                                    />
                                    )
                                ) : socialCh === "whatsapp" ? (
                                    inqData?.whatsapp[0]?.profile_image ? (
                                    <img
                                        src={inqData.whatsapp[0].profile_image}
                                        alt="Profile Image"
                                        className="hover:opacity-80 cursor-pointer"
                                        onClick={() => handleOpenModal()}
                                    />
                                    ) : (
                                    <img
                                        src={contactImg2}
                                        alt="Profile Image"
                                        className="hover:opacity-80 cursor-pointer"
                                        onClick={() => handleOpenModal()}
                                    />
                                    )
                                ) : socialCh === "caller" ? (
                                    inqData?.whatsapp[0]?.profile_image ? (
                                    <img
                                        src={inqData.whatsapp[0].profile_image}
                                        alt="Profile Image"
                                        className="hover:opacity-80 cursor-pointer"
                                        onClick={() => handleOpenModal()}
                                    />
                                    ) : (
                                    <img
                                        src={contactImg2}
                                        alt="Profile Image"
                                        className="hover:opacity-80 cursor-pointer"
                                        onClick={() => handleOpenModal()}
                                    />
                                    )
                                ) : (
                                    <img
                                    src={contactImg2}
                                    alt="Profile Image"
                                    className="hover:opacity-80 cursor-pointer"
                                    onClick={() => handleOpenModal()}
                                    />
                                )
                                }
                            {socialCh === "insta" && (accData?.mobile && <h5 className="text-white mt-3">{accData?.mobile}</h5>)}
                            {socialCh === "whatsapp" && (inqData?.whatsapp[0]?.mobile && <h5 className="text-white mt-3" dir="ltr">{inqData?.whatsapp[0]?.mobile }</h5>)}
                            {socialCh === "caller" && (accData?.mobile && <h5 className="text-white mt-3">{accData?.mobile}</h5>)}
                        </div>
                        <div className="right-container">
                             <h3 className="text-white mb-2 text-right">اطلاعات کاربر</h3> 
                            <table>
                                <tbody className="text-center">
                                    <tr className="flex justify-between items-center">
                                    <td>
                                            {socialCh === "caller" ? "سرویس هویت تلفنی" : "شبکه اجتماعی"}
                                        </td>
                                        <td className="flex justify-between items-center">
                                            {socialCh === "telegram" && <span className='inline-block text-white text-2xl social-icon'><FontAwesomeIcon icon={faTelegram} /></span>}
                                            {socialCh === "insta" && <span className='inline-block fill-gradient-instagram-text text-white text-2xl'><FontAwesomeIcon icon={faInstagram} /></span>}
                                            {socialCh === "twitter" && <span className='inline-block text-white text-2xl social-icon'><FontAwesomeIcon icon={faXTwitter} /></span>}
                                            {socialCh === "fb" && <span className='inline-block text-white text-2xl social-icon'><FontAwesomeIcon icon={faFacebook} /></span>} 
                                            {socialCh === "whatsapp" && <span className='inline-block text-white text-2xl social-icon'><FontAwesomeIcon icon={faWhatsapp} /></span>}
                                            {socialCh === "linkedin" && <span className='inline-block text-white text-2xl social-icon'><FontAwesomeIcon icon={faLinkedin} /></span>}
                                            {socialCh === "caller" && <span className='inline-block text-white text-2xl social-icon'><FontAwesomeIcon icon={faPhone} /></span>}
                                        </td>
                                    </tr>
                                    {
                                        socialCh === "insta" && (
                                            <>
                                                <tr className="flex justify-between items-center">
                                                    <td>نوع اکانت</td>
                                                    <td>
                                                        {accData?.public == 1 && "Public"}
                                                        {accData?.public == 0 && "Private"}
                                                    </td>
                                                </tr>
                                                {accData?.username &&
                                                    <>
                                                        <tr className="flex justify-between items-center">
                                                            <td>نام کاربری</td>
                                                            <td> {accData?.username}
                                                            </td>
                                                        </tr>
                                                        <tr className="flex justify-between items-center">
                                                            <td className="">شناسه کاربری‌</td>
                                                            <td>
                                                                {accData?.user_id}
                                                            </td>
                                                        </tr>

                                                        <tr className="flex justify-between items-center">
                                                            <td>نام و نام خانوادگی‌</td>
                                                            <td>
                                                                {accData?.name}
                                                            </td>
                                                        </tr>
                                                        <tr className="flex justify-between items-center">
                                                            <td>تعداد فالوئر‌</td>
                                                            <td>{accData?.follower_count}</td>
                                                        </tr>
                                                        <tr className="flex justify-between items-center">
                                                            <td>تعداد فالوئینگ</td>
                                                            <td>{accData?.following_count}</td>
                                                        </tr>
                                                        <tr className="flex justify-between items-center">
                                                            <td>بیوگرافی‌</td>
                                                            <td className=" cursor-pointer select-none" onClick={() => handleOpenbio()}>{`${accData?.biography?.length > 7
                                                                ? `
                                                                  ...${accData?.biography?.slice(0, 20)}`
                                                                : accData?.biography}`}</td>
                                                            {openBio &&
                                                                <div className='bioWrap'>
                                                                    <div className='fullScreenImage'>
                                                                        <p className="text-center text-white">
                                                                            <div className="flex justify-between border-b border-dark-blue">
                                                                                <p className='flex justify-between text-2xl text-gray-900 m-0'><span>بیوگرافی‌ کامل</span></p>
                                                                                <span className=" text-dark-blue hover:opacity-70"><FontAwesomeIcon icon={faXmark} className=' cursor-pointer select-none' onClick={handleClosebio} /></span>
                                                                            </div>
                                                                            <p className=' overflow-y-auto text-gray-900 text-lg p-6 h-4/5'>{accData?.biography}</p>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </tr>
                                                    </>}
                                            </>
                                        )
                                    }
                                    {
                                        socialCh === "whatsapp" && (
                                            <>
                                                {/* وضعیت اکانت */}
                                                {accData?.whatsapp_status === 1 && 
                                                <tr className="flex justify-between items-center">
                                                    <td>نوع اکانت</td>
                                                    <td>
                                                        {inqData?.whatsapp[0] &&
                                                            (inqData?.whatsapp[0]?.isBusiness ? "Business" : "Personal")}
                                                    </td>
                                                </tr>}
                                                
                                                {/* وضعیت بیوگرافی */}
                                                {accData?.whatsapp_status === 1 && 
                                                <tr className="flex justify-between items-center">
                                                    <td>درباره</td>
                                                    <td>{ inqData?.whatsapp[0]?.biography ? inqData?.whatsapp[0]?.biography : "ندارد"}</td>
                                                </tr>}
                                                
                                                {/* کشور */}
                                                {inqData?.whatsapp[0]?.countryCode && (
                                                    <tr className="flex justify-between items-center">
                                                        <td>کشور</td>
                                                        <td>{accData?.country_name}</td>
                                                    </tr>
                                                )}
                                                
                                                {/* وضعیت واتساپ */}
                                                <tr className="flex justify-between items-center">
                                                    <td> واتساپ</td>
                                                    <td>
                                                        {accData?.whatsapp_status === 1 ? (
                                                            <span className="text-green-400">واتساپ دارد</span>
                                                        ) : accData?.whatsapp_status === 2 ? (
                                                            <span className="text-red-500">ندارد</span>
                                                        ) : (
                                                            <span className="text-blue-500">در حال استخراج اطلاعات</span>
                                                        )}
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    }

                                    {
                                        socialCh === "caller" && (
                                            <>
                                                {/* {
                                                    <tr className="flex justify-between items-center">
                                                        <td>نوع شماره</td>
                                                        <td> {inqData?.caller[0] && (inqData?.caller[0]?.spam ? "شماره اسپم می باشد" : " شماره درست می باشد ")}</td>
                                                    </tr>} */}
                                                {
                                                    <tr className="flex justify-between items-center">
                                                        <td>شماره تلفن</td>
                                                        <td>{inqData.mobile ? inqData?.mobile : " شماره تلفن بدون استعلام"}
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    <tr className="flex justify-between items-center">
                                                        <td>نام</td>
                                                        <td> {inqData?.caller[0]?.name?.[0]?.name ? inqData?.caller[0]?.name?.[0]?.name :  <span className="text-blue-500">در حال استخراج اطلاعات</span>}
                                                        </td>
                                                        
                                                    </tr>
                                                }
                                                {
                                                    <tr className="flex justify-between items-center">
                                                        <td>وضعیت استعلام</td>
                                                        {console.log(accData?.caller_status)}
                                                        <td>
                                                        {accData?.caller_status === 1 ? (
                                                            <span className="text-green-400">شماره یافت شد</span>
                                                        ) : inqData?.caller[0]?.caller_status === 2 ? (
                                                            <span className="text-red-500">شماره یافت نشد</span>
                                                        ) : (
                                                            <span className="text-blue-500">در حال استخراج اطلاعات</span>
                                                        )}
                                                         </td>
                                                    </tr>
                                                }
                                                {
                                                    // <tr className="flex justify-between items-center">
                                                    //     <td>تاریخ ثبت</td>
                                                    //     <td>
                                                    //     {accData?.caller_status === 1 ? (
                                                    //         <span className="text-green-400">شماره یافت شد</span>
                                                    //     ) : accData?.caller_status === 2 ? (
                                                    //         <span className="text-red-500">شماره یافت نشد</span>
                                                    //     ) : (
                                                    //         <span className="text-blue-500">در حال استخراج اطلاعات</span>
                                                    //     )}
                                                    //      </td>
                                                    // </tr>
                                                }
                                            </>
                                        )
                                    }
                                </tbody>
                            </table>
                            {/* <div className="social-icons absolute bg-white p-2 rounded-lg lg:left-0 lg:flex-col lg:ml-6">
                                 <NavLink href="#" className={`hover:text-blue-700 relative hover:bg-none hover:bg-white ${socialCh === "" ? "taken" : ""}`} onMouseEnter={() => setTTipShow(true)} onMouseLeave={() => setTTipShow(false)}>
                                    <span className="mt-2 select-none transition-all duration-100 ease-in"><FontAwesomeIcon icon={faEllipsis} /></span>
                                    <div className=" absolute flex flex-col p-2 rounded border-3 border-dark-blue bottom-10 text-sm text-dark-blue  select-none transition-all duration-100 ease-in" style={tTipShow ? { "visibility": "visible", "opacity": 1, "backgroundColor": "#fbfbfbe7" } : { "visibility": "hidden", "opacity": 0 }}>
                                        <div className="flex border-b border-dark-blue">
                                            <p className='flex justify-between text-gray-900 mb-1 text-xs p-1'><span>سایر شبکه‌های اجتماعی</span></p>
                                        </div>
                                        <div className="flex justify-around w-72 p-2">
                                            <span className="flex justify-center items-center border-2 border-middle-green rounded-2xl p-1 text-2xl m-1 bg-white text-black"><FontAwesomeIcon icon={faTiktok} /></span>
                                            <span className="flex justify-center items-center border-2 border-middle-green rounded-2xl p-1 text-2xl m-1 bg-white text-orange-500"><FontAwesomeIcon icon={faReddit} /></span>
                                            <span className="flex justify-center items-center border-2 border-middle-green rounded-2xl p-1 text-2xl m-1 bg-purple-600 text-white"><FontAwesomeIcon icon={faViber} /></span>
                                            <span className="flex justify-center items-center border-2 border-middle-green rounded-2xl p-1 text-2xl m-1 bg-white text-blue-700"><FontAwesomeIcon icon={faMeta} /></span>
                                            <span className="flex justify-center items-center border-2 border-middle-green rounded-2xl p-1 text-2xl m-1 bg-white text-blue-800"><FontAwesomeIcon icon={faWordpress} /></span>
                                            <span className="flex justify-center items-center border-2 border-middle-green rounded-2xl p-1 text-2xl m-1 bg-white text-black"><FontAwesomeIcon icon={faGithub} /></span>
                                        </div>
                                    </div>
                                </NavLink> 
                                {inq?.telegram_status == 3 && <NavLink href="#" className={`hover:text-blue-600 hover:bg-none mr-4 lg:mr-0 lg:mt-4 hover:bg-white ${socialCh === "telegram" ? "taken" : ""}`} onClick={() => { setSocailCh("telegram"); setContactImg(contactImgTel) }}><FontAwesomeIcon icon={faTelegramPlane} /></NavLink>}
                                {inq?.whatsapp_status == 1 && <NavLink href="#" className={`hover:text-green-700 hover:bg-none mr-4 lg:mr-0 lg:mt-4 hover:bg-white ${socialCh === "whatsapp" ? "taken" : ""}`} onClick={() => { setSocailCh("whatsapp"); setContactImg(contactImgTw) }}><FontAwesomeIcon icon={faWhatsapp} /></NavLink>}
                                {inq?.caller_status == 1 && <NavLink href="#" className={`hover:text-green-700 hover:bg-none mr-4 lg:mr-0 lg:mt-4 hover:bg-white ${socialCh === "caller" ? "taken" : ""}`} onClick={() => { setSocailCh("caller"); setContactImg(contactImgTw) }}><FontAwesomeIcon icon={faUser} /></NavLink>}
                                {inq?.status == 3 && <NavLink href="#" className={`fill-gradient-instagram-text mr-4 lg:mr-0 lg:mt-4 hover:text-white ${socialCh === "insta" ? "taken" : ""}`} onClick={() => { setSocailCh("insta"); setContactImg(contactImginsta) }}><FontAwesomeIcon icon={faInstagram} /></NavLink>}
                                {inq?.x_status == 3 && <NavLink href="#" className={`hover:text-black hover:bg-none mr-4 lg:mr-0 lg:mt-4 hover:bg-white ${socialCh === "twitter" ? "taken" : ""}`} onClick={() => { setSocailCh("twitter"); setContactImg(contactImgTw) }}><FontAwesomeIcon icon={faXTwitter} /></NavLink>}
                                {inq?.linkedin_status == 3 && <NavLink href="#" className={`hover:text-blue-700 hover:bg-none mr-4 lg:mr-0 lg:mt-4 hover:bg-white ${socialCh === "linkedin" ? "taken" : ""}`} onClick={() => { setSocailCh("twitter"); setContactImg(contactImgTw) }}><FontAwesomeIcon icon={faLinkedin} /></NavLink>}
                                {inq?.fb_status == 3 && <NavLink href="#" className={`hover:text-blue-700 hover:bg-none mr-4 lg:mr-0 lg:mt-4 hover:bg-white ${socialCh === "fb" ? "taken" : ""}`} onClick={() => { setSocailCh("fb"); setContactImg(contactImgTel) }}><FontAwesomeIcon icon={faFacebook} /></NavLink>} 
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

export default Result
