import { useState, useContext, useEffect } from "react";
import UIContext from "../Context/UiContext";
import axios from "../Api/axios";
import Header from "../Header/Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardUser } from '@fortawesome/free-solid-svg-icons';
import InstaDet from "./InstaDet";
import SinUserNInp from "../SinUserNInp";
import FollowerDet from "../FollowerDet";

const AccDet = () => {

    const { socialCh, setSocailCh, show, inpShow, setmInpShow } = useContext(UIContext);
    const [typeShow, setTypeShow] = useState();
    useEffect(() => {
        setmInpShow(true);
    }, []); // Run
    console.log({ setmInpShow, inpShow });
    function turnToTelegram() {
        setmInpShow(true);
        setSocailCh('telegram');
        setTypeShow(false);
    }

    function turnToWhatsapp() {
        setmInpShow(true);
        setSocailCh('whatsapp');
        setTypeShow(false);
    }

    function turnToInsta() {
        setmInpShow(true);
        setSocailCh('insta');
        setTypeShow(false);
    }

    function turnToTwitter() {
        setmInpShow(true);
        setSocailCh('twitter');
        setTypeShow(false);
    }

    function turnToFb() {
        setmInpShow(true);
        setSocailCh('fb');
        setTypeShow(false);
    }

    const actFilter = {
        backgroundColor: "rgb(1, 31, 75)",
        color: "white",
        borderLeftColor: "rgb(1, 31, 75)",
    };

    const dActFilter = {
        // backgroundColor: "transparent"
    }

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     set
    // };

    return (
        <>
            <Header />
            <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52'>
                <div className="md:col-span-7 bg-white md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
                    <section>
                        {show ?
                            <>
                                <span className='inline-block mr-4 text-blue-800 text-lg ml-2'><FontAwesomeIcon icon={faClipboardUser} /></span>
                                <h5 className="inline-flex items-center">فالوئر و فالوئینگ‌</h5>
                                <div>
                                    <p className=' px-8 text-sm md:text-base'>
                                        برای استعلام فالوئر و فالوئینگ‌ها شناسه کاربری مورد نظر را مشخص نمایید.
                                    </p>
                                    {inpShow ? 
                                        <>
                                            <SinUserNInp />
                                        </>
                                        : ''}
                                </div>
                            </> : <FollowerDet />
                        }
                    </section>
                </div>
            </div>
        </>
    )
}

export default AccDet


