import { useContext, useState, useRef,useEffect } from "react";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { axiosPrivate } from "../Api/axios";
import UIContext from '../Context/UiContext';
import { faClipboardQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BulkInp from "../number page/BulkInp";

function InpInqWhatsapp() {

    const { 
        setInq,
        setDetShow,
        setSocailCh,
        setShow,
        setTableShow,
        setSubAlert,
        setInqShow,
        inqData,
        setInqData,
        setAccData } = useContext(UIContext);

    const [input, setInput] = useState(null);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);
    const [inputKey, setInputKey] = useState(Date.now());

    const [phoneData, setPhoneData] = useState({
        phone: '',
        country: '',
        countryCode: ''
    });
    const iranPhonePattern = /^(\+98|98)?9\d{9}$/;

    
    const handlePhoneChange = (value, selectedCountry, selectedCountryCode) => {
        
        // Update the state with dynamic country and countryCode
        setPhoneData((prevState) => ({
            ...prevState,
            phone: value,
            country: selectedCountry?.name || "Unknown",
            countryCode: selectedCountryCode,
            priority: selectedCountry?.priority || 0,
        }));
        // console.log("Phone data changed:", {
        //     phone: value,
        //      country: selectedCountry,
        //       countryCode: selectedCountryCode,
        //  });
 
        //  console.log("Selected Country Details:", selectedCountry);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const cleanPhone = phoneData.phone.startsWith('+') ? phoneData.phone.substring(1) : phoneData.phone;
        console.log("Cleaned Phone Number:", cleanPhone); // Logs the cleaned phone number without '+'

        if (!cleanPhone) {
            console.log("Phone data is empty, submission blocked.");
            alert("لطفاً شماره معتبری وارد کنید.");
            return;
        }
        // if (phoneData.country === 'IR' && !iranPhonePattern.test(cleanPhone)) {
        //     alert("شماره وارد شده باید یک شماره موبایل معتبر ایرانی باشد.");
        //     return;
        // }
        setShow(false);
        setTableShow(true);
        setInput(cleanPhone);
        setInputKey(Date.now()); // This changes on every submission
        setSubAlert(true);
        setInqShow(true);

        // Clear the input after submission
        setPhoneData({
            phone: '',
            country: '',
            countryCode: ''
        });
        inputRef.current.value = '';
    };
    const dontHandle = () => {
        alert("لطفا ورودی را مشخص کنید")
    };
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getList = async () => {
            try {
                setLoading(true);
                const response = await axiosPrivate.get(`/check/mobile/${input}`, {
                    signal: controller.signal
                });
                console.log(response);
                response?.data?.code == 403 && setLoading(false);
                if (isMounted) {
                    setInqData(response?.data);
                    if (response?.data?.code === "200") {
                        setInq(response?.data?.data[0]);
                        //my mix
                        setSocailCh("whatsapp");
                        //? 
                        setAccData(response.data?.data[0]);
                        //
                        setDetShow(false);
                        setInqShow(true);
                        
                    }
                }
                setLoading(false);
            } catch (err) {
                console.error("Axios Error:", {
                    message: err.message,
                    code: err.code,
                    config: err.config,
                    stack: err.stack,
                });
            }
        }

        input && getList();

        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [input, inputKey]);
       
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const MySubmit = async () => {
            try {
                setLoading(true);
                const res = await axiosPrivate.post(`/add/mobile`, { input });
                setLoading(false);
                console.log("Response from /add/mobile:", res);
                if (!res || !res.data) {
                    console.warn("Response data is missing.");
                }
            } catch (err) {
                console.error("Axios Error:", {
                    message: err.message,
                    code: err.code,
                    config: err.config,
                    stack: err.stack,
                });
                console.error( "Error in MySubmit:", err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        };
        if (inqData) {
            console.log("inqData:", inqData);
    
            if (inqData?.code === "300") {
                MySubmit();
            }
    
            if (inqData?.code === "200" && inqData?.data?.[0]) {
                setAccData(inqData.data[0]);
            } else if (inqData?.code === "200") {
                console.warn("inqData.data[0] is undefined.");
            }
        }
        if (!inqData) {
            //console.warn("محل خیره شماره خالی شد.");
        } else if (!inqData?.data) {
           // console.warn("ورودی در سیستم وجود ندارد");
        }
        
        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [input, inqData]);

    return (
        <section className="inq-result">
            <div className="">
                <span className='inline-block place-content-center mr-4 text-blue-800 dark:text-blue-300 text-lg ml-2'>
                    <FontAwesomeIcon icon={faClipboardQuestion} />
                </span>
                <h5 className="inline-flex items-center">استعلام اکانت</h5>
                <div className="">
                    <div className="mt-4">
                        <div className="lg:w-1/2 inq-holder">
                            <div className="mx-auto text-left">
                                <form onSubmit={handleSubmit} className="flex items-center justify-center">
                                    <label className="ml-2">ورود مشخصه تکی:</label>
                                    <div className="inline-block" style={{ "direction": "ltr" }}>
                                    <span>{phoneData.countryCode}</span>
                                        <PhoneInput
                                            defaultCountry="ir" // Set default country to Iran
                                            value={phoneData.phone}
                                            onChange={handlePhoneChange}
                                            showCountryFlag={true}
                                            showCountryCode={true}
                                            ref={inputRef}
                                             placeholder="9XXXXXXXX"
                                        // style={{ width: '300px', height: '45px', fontSize: '16px' }} // Customize size here
                                        />
                                    </div>
                                    <button
                                        type={phoneData.phone ? "submit" : "button"}
                                        onClick={!phoneData.phone ? dontHandle : undefined}
                                        className={`py-2 px-4 h-10 subBut text-white hover:bg-yellow bg-light-green rounded mr-2 cursor-pointer items-center`}
                                        disabled={loading} 
                                    >
                                        {loading ? 'در حال ارسال...' : 'ثبت'}
                                    </button>
                                </form>
                            </div>
                        </div>
                        <BulkInp />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InpInqWhatsapp;
