import { useContext, useState, useEffect, useRef } from "react";

import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { axiosPrivate } from "../Components/Api/axios";
import UIContext from "./Context/UiContext"; 
import contactImginsta from '../img/hipster-avatar-image-vector.jpg';
import contactImgTw from '../img/contact.svg';
import contactImgFa from '../img/hipster-avatar-image-vector.jpg';
import contactImgTel from '../img/sample-avatar-min.jpg';
import { faCheckSquare, faQuestionCircle, faXmarkSquare, faClipboardQuestion, faX, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTelegram, faTwitter, faWhatsapp, faFacebook, faLinkedIn, faLinkedin, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import InqTable from "./InqTable";
import BulkInp from "../Components/number page/BulkInp";

function InpInq() {

    const { setDetShow, setSocailCh, setContactImg, inqData, accData, setAccData, inpKind, setInqData, inq, setInq, subAlert, setInpKind, setShow, setTableShow, setSubAlert, show, tableShow, inqShow, setInqShow } = useContext(UIContext);
    const [input, setInput] = useState(null);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);
    const [inputKey, setInputKey] = useState(Date.now());

    const [phoneData, setPhoneData] = useState({
        phone: '',
        country: '',
        countryCode: ''
    });

    const iranPhonePattern = /^(\+98|98)?9\d{9}$/;

    const handlePhoneChange = (value, selectedCountry, selectedCountryCode) => {
        // console.log("Phone data changed:", {
        //     phone: value,
        //     country: selectedCountry,
        //     countryCode: selectedCountryCode,
        // });

        const country = selectedCountry?.name || "Unknown Country";
        const fullNumber = `${selectedCountryCode}${value}`;
        console.log("Selected Country Details:", selectedCountry);
        // Update the state with dynamic country and countryCode
        setPhoneData((prevState) => ({
            ...prevState,
            phone: value,
            country: selectedCountry?.name || "Unknown",
            countryCode: selectedCountryCode,
            //priority: selectedCountry?.priority || 0,
        }));
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const cleanPhone = phoneData.phone.startsWith('+') ? phoneData.phone.substring(1) : phoneData.phone;
        console.log("Cleaned Phone Number:", cleanPhone); // Logs the cleaned phone number without '+'

    // Validate only if the selected country is Iran (IR)
    if (!cleanPhone) {
        alert("لطفاً شماره معتبری وارد کنید.");
        return;
    }
    if (phoneData.country === 'IR' && !iranPhonePattern.test(cleanPhone)) {
        alert("شماره وارد شده باید یک شماره موبایل معتبر ایرانی باشد.");
        return;
    }
        setShow(false);
        setTableShow(true);
        setInput(cleanPhone);
        setInputKey(Date.now()); // This changes on every submission
        setSubAlert(true);
        setInqShow(true);
        
        // Clear the input after submission
        setPhoneData({
            phone: '',
            country: '',
            countryCode: ''
        });
        inputRef.current.value = '';
    };

    const dontHandle = () => {
        alert("لطفا ورودی را مشخص کنید")
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getList = async () => {
            try {
                setLoading(true);
                const response = await axiosPrivate.get(`/check/mobile/${input}`, {
                    signal: controller.signal
                });
                console.log(response);
                response?.data?.code == 403 && setLoading(false);
                if (isMounted) {
                    setInqData(response?.data);
                    if (response?.data?.code === "200") {
                        setInq(response?.data?.data[0]);
                    }
                }
                setLoading(false);
            } catch (err) {
                console.error("Axios Error:", {
                    message: err.message,
                    code: err.code,
                    config: err.config,
                    stack: err.stack,
                });
            }
        }

        input && getList();

        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [input, inputKey]);


    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const MySubmit = async () => {
            try {
                setLoading(true);
                const res = await axiosPrivate.post(`/add/mobile`, { input });
                setLoading(false);
                console.log("Response from /add/mobile:", res);
                if (!res || !res.data) {
                    console.warn("Response data is missing.");
                }
            } catch (err) {
                console.error("Axios Error:", {
                    message: err.message,
                    code: err.code,
                    config: err.config,
                    stack: err.stack,
                });
                console.error( "Error in MySubmit:", err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        };
        if (inqData) {
            console.log("inqData:", inqData);
    
            if (inqData?.code === "300") {
                MySubmit();
            }
    
            if (inqData?.code === "200" && inqData?.data?.[0]) {
                setAccData(inqData.data[0]);
            } else if (inqData?.code === "200") {
                console.warn("inqData.data[0] is undefined.");
            }
        }
        if (!inqData) {
            //console.warn("محل خیره شماره خالی شد.");
        } else if (!inqData?.data) {
           // console.warn("ورودی در سیستم وجود ندارد");
        }
        
        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [input, inqData]);

    const reportingDetails = () => {
        setDetShow(false);
    };
    return (
        <section className="inq-result">
            {
                !inqShow ? <div className="">
                    <span className='inline-block  place-content-center mr-4 text-blue-800 dark:text-blue-300 text-lg ml-2'><FontAwesomeIcon icon={faClipboardQuestion} /></span>
                    <h5 className="inline-flex items-center">استعلام شماره</h5>
                    <div className="">
                        {/* <div className="flex items-center px-8">
                            <p className="m-0">نوع استعلام را مشخص کنید:</p>
                            <ul className="m-0 flex">md:w-1/3 w-full p-4 flex flex-col items-center
                                <li onClick={() => { setInpKind("mobile"); }} className={`p-2 hover:bg-yellow text-white text-sm mr-1  ${inpKind === "mobile" ? "bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`}><span>شماره موبایل </span></li>
                                <li onClick={() => { setInpKind("id"); }} className={`p-2 hover:bg-yellow text-white text-sm mr-1  ${inpKind === "id" ? "bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`}><span>شناسه کاربری </span></li>
                                <li onClick={() => { setInpKind("username"); }} className={`p-2 hover:bg-yellow text-white text-sm mr-1  ${inpKind === "username" ? "bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`}><span>نام کاربری </span></li>
                            </ul>
                        </div> */}
                        <div className="mt-4">
                            <div className="lg:w-1/2 inq-holder">
                                <div className="mx-auto text-left">
                                    <form onSubmit={handleSubmit} className="flex items-center justify-center">
                                        <label className="ml-2">ورود مشخصه تکی:</label>
                                        <div className="inline-block" style={{ "direction": "ltr" }}>
                                        <span>{phoneData.countryCode}</span>
                                            <PhoneInput
                                                forceDialCode="true"
                                                defaultCountry="ir" 
                                                value={phoneData.phone}
                                                onChange={handlePhoneChange}
                                                showCountryFlag={true}
                                                showCountryCode={true}
                                                ref={inputRef}
                                                placeholder="9XXXXXXXX"
                                            // style={{ width: '300px', height: '45px', fontSize: '16px' }} // Customize size here
                                            />
                                        </div>
                                        <button 
                                                type={phoneData.phone ? "submit" : "button"} 
                                                onClick={!phoneData.phone ? dontHandle : undefined} 
                                                className={`py-2 px-4 h-10 subBut text-white hover:bg-yellow bg-light-green rounded mr-2 cursor-pointer items-center`}
                                            >
                                                ثبت
                                            </button>


                                    </form>
                                </div>
                            </div>
                            <BulkInp />
                        </div>
                    </div>
                </div>
                    : <div className="transition-all duration-200 ease-in" style={show ? { display: "none", opacity: 0, height: 0 } : { display: "block", opacity: 1, height: "auto" }}>
                        <h5 className='inline-flex items-center'><span className='inline-block place-content-center align-top text-green-600 text-lg ml-2'><FontAwesomeIcon icon={faCheckSquare} /></span><span>نتیجه استعلام</span><button className={`py-1 px-2 hover:bg-yellow text-white text-base mr-1 bg-light-green rounded select-none transition-all duration-200 ease-in`} onClick={() => { setInqShow(false); setAccData(null); setInqData(null) }}>بازگشت</button>
                        </h5>
                        {
                            loading ? <div className='flex'>< p className=' text-right text-lg mt-2 pr-6' > در حال بارگذاری...</p></div>
                                : tableShow ? <>
                                    <p className=' px-8'>
                                        <span className="inline-block ml-1">برای مشاهده جزئیات هر اکانت بر روی گزینه مورد نظر کلیک نمایید.</span>
                                        {
                                            inqData?.code == 300 ? <span className=" bg-green-200 inline-block p-2 w-fit mx-auto text-center text-sm rounded mt-8 transition-all duration-200 ease-in select-none" style={subAlert ? { "visibility": "visible", "opacity": 1 } : { "visibility": "hidden", "opacity": 0 }}>ورودی از قبل در سیستم وجود نداشت و اضافه شد.</span> : ''
                                        }
                                    </p>
                                    <div className="flex lg:w-1/2 mx-auto mt-4 flex-col justify-center bg-gray-200 shadow-sm shadow-middle-green rounded">
                                        <div className="flex justify-between items-center bg-middle-green text-white border-b border-b-gray-400 rounded-t p-2">
                                            <div className="flex items-left"><span className="">شماره: {inqData?.mobile}</span></div>
                                        </div>
                                        <div onClick={() => { inq?.status == 3 && reportingDetails(); setSocailCh("insta"); setContactImg(contactImginsta) }} className="flex justify-between items-center border-b border-b-gray-400 p-1 cursor-pointer hover:bg-gray-300 transition-all duration-100 ease-in">
                                            <div className="flex items-center mr-4"><span className='inline-block place-content-center align-top fill-gradient-instagram text-white text-lg ml-2'><FontAwesomeIcon icon={faInstagram} /></span><span className="">اینستاگرام</span></div>
                                            <div className="flex items-center w-16 justify-center">
                                                {inqData?.code == 200 && <span>
                                                    {inq?.status == 3 && <span className='inline-block place-content-center align-top text-green-500 text-lg ml-2'><FontAwesomeIcon icon={faCheckSquare} /></span>}
                                                    {inq?.status == 2 && <span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faXmarkSquare} /></span>}
                                                    {inq?.status == 1 && <span className='inline-block place-content-center align-top text-blue-500 text-base w-40 ml-2'>در انتظار استعلام</span>}
                                                    {inq?.status == 0 && <span className='inline-block place-content-center align-top text-gray-500 text-base w-40 ml-2'>در انتظار بررسی</span>}
                                                </span>
                                                }
                                                {
                                                    inqData?.code == 300 && <span className='inline-block place-content-center align-top text-gray-500 text-lg ml-2'><FontAwesomeIcon icon={faQuestionCircle} /></span>
                                                }
                                            </div>
                                        </div>
                                        <div onClick={() => { inq?.telegram_status == 3 && reportingDetails(); setSocailCh("telegram"); setContactImg(contactImgTel) }} className="flex justify-between items-center border-b border-b-gray-400 p-1 cursor-pointer hover:bg-gray-300 transition-all duration-100 ease-in">
                                            <div className="flex items-center mr-4"><span className='inline-block place-content-center align-top text-blue-600 text-lg ml-2'><FontAwesomeIcon icon={faTelegram} /></span><span className="">تلگرام</span></div>
                                            <div className="flex items-center w-16 justify-center">
                                                {inqData?.code == 200 && <span>
                                                    {inq?.telegram_status == 3 && <span className='inline-block place-content-center align-top text-green-500 text-lg ml-2'><FontAwesomeIcon icon={faCheckSquare} /></span>}
                                                    {inq?.telegram_status == 2 && <span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faXmarkSquare} /></span>}
                                                    {inq?.telegram_status == 1 && <span className='inline-block place-content-center align-top text-blue-500 text-base w-40 ml-2'>در انتظار استعلام</span>}
                                                    {inq?.telegram_status == 0 && <span className='inline-block place-content-center align-top text-gray-500 text-base w-40 ml-2'>در انتظار بررسی</span>}
                                                </span>
                                                }
                                                {
                                                    inqData?.code == 300 && <span className='inline-block place-content-center align-top text-gray-500 text-lg ml-2'><FontAwesomeIcon icon={faQuestionCircle} /></span>
                                                }
                                            </div>
                                        </div>
                                        <div onClick={() => { inq?.whatsapp_status == 1 && reportingDetails(); setSocailCh("whatsapp"); setContactImg(contactImgTw) }} className="flex justify-between items-center border-b border-b-gray-400 p-1 cursor-pointer hover:bg-gray-300 transition-all duration-100 ease-in">
                                            <div className="flex items-center mr-4"><span className='inline-block place-content-center align-top text-green-600 text-lg ml-2'><FontAwesomeIcon icon={faWhatsapp} /></span><span className="">واتساپ</span></div>
                                            <div className="flex items-center w-16 justify-center">
                                                {inqData?.code == 200 && <>
                                                    {inq?.whatsapp_status == 1 && <span className='inline-block place-content-center align-top text-green-500 text-lg ml-2 mt-1'><FontAwesomeIcon icon={faCheckSquare} /></span>}
                                                    {inq?.whatsapp_status == 2 && <span className='inline-block place-content-center align-top text-red-500 text-lg ml-2 mt-1'><FontAwesomeIcon icon={faXmarkSquare} /></span>}
                                                    {inq?.whatsapp_status == 3 && <span className='inline-block place-content-center align-top text-blue-500 text-base w-40 ml-2'>در انتظار استعلام</span>}
                                                    {inq?.whatsapp_status == 0 && <span className='inline-block place-content-center align-top text-gray-500 text-base w-40 ml-2'>در انتظار بررسی</span>}
                                                </>
                                                }
                                                {
                                                    inqData?.code == 300 && <span className='inline-block place-content-center align-top text-gray-500 text-lg ml-2'><FontAwesomeIcon icon={faQuestionCircle} /></span>
                                                }
                                            </div>
                                        </div>
                                        <div onClick={() => { inq?.x_status == 3 && reportingDetails(); setSocailCh("twitter"); setContactImg(contactImgTw) }} className="flex justify-between items-center border-b border-b-gray-400 p-1 cursor-pointer hover:bg-gray-300 transition-all duration-100 ease-in">
                                            <div className="flex items-center mr-4"><span className='inline-block place-content-center align-top text-black text-lg ml-2'><FontAwesomeIcon icon={faXTwitter} /></span><span className="">ایکس(توییتر)</span></div>
                                            <div className="flex items-center w-16 justify-center">
                                                {inqData?.code == 200 && <span>
                                                    {inq?.x_status == 3 && <span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faCheckSquare} /></span>}
                                                    {inq?.x_status == 2 && <span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faXmarkSquare} /></span>}
                                                    {inq?.x_status == 1 && <span className='inline-block place-content-center align-top text-blue-500 text-base w-40 ml-2'>در انتظار استعلام</span>}
                                                    {inq?.x_status == 0 && <span className='inline-block place-content-center align-top text-gray-500 text-base w-40 ml-2'>در انتظار بررسی</span>}
                                                </span>
                                                }
                                                {
                                                    inqData?.code == 300 && <span className='inline-block place-content-center align-top text-gray-500 text-lg ml-2'><FontAwesomeIcon icon={faQuestionCircle} /></span>
                                                }
                                            </div>
                                        </div>
                                        <div onClick={() => { inq?.linkedin_status == 3 && reportingDetails(); setSocailCh("linkedin"); setContactImg(contactImgFa) }} className="flex justify-between items-center border-b border-b-gray-400 p-1 cursor-pointer hover:bg-gray-300 transition-all duration-100 ease-in">
                                            <div className="flex items-center mr-4"><span className='inline-block place-content-center align-top text-blue-700 text-lg ml-2'><FontAwesomeIcon icon={faLinkedin} /></span><span className="">لینکدین</span></div>
                                            <div className="flex items-center w-16 justify-center">
                                                {inqData?.code == 200 && <span>
                                                    {inq?.linkedin_status == 3 && <span className='inline-block place-content-center align-top text-green-500 text-lg ml-2'><FontAwesomeIcon icon={faCheckSquare} /></span>}
                                                    {inq?.linkedin_status == 2 && <span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faXmarkSquare} /></span>}
                                                    {inq?.linkedin_status == 1 && <span className='inline-block place-content-center align-top text-blue-500 text-base w-40 ml-2'>در انتظار استعلام</span>}
                                                    {inq?.linkedin_status == 0 && <span className='inline-block place-content-center align-top text-gray-500 text-base w-40 ml-2'>در انتظار بررسی</span>}
                                                </span>
                                                }
                                                {
                                                    inqData?.code == 300 && <span className='inline-block place-content-center align-top text-gray-500 text-lg ml-2'><FontAwesomeIcon icon={faQuestionCircle} /></span>
                                                }
                                            </div>
                                        </div>
                                        <div onClick={() => { inq?.caller_status == 1 && reportingDetails(); setSocailCh("caller"); setContactImg(contactImgTw) }} className="flex justify-between items-center border-b border-b-gray-400 p-1 cursor-pointer hover:bg-gray-300 transition-all duration-100 ease-in">
                                            <div className="flex items-center mr-4"><span className='inline-block place-content-center align-top text-blue-700 text-lg ml-2'><FontAwesomeIcon icon={faPhone} /></span><span className="">هویت تلفنی</span></div>
                                            <div className="flex items-center w-16 justify-center">
                                                {inqData?.code == 200 && <span>
                                                    {inq?.caller_status == 1 && <span className='inline-block place-content-center align-top text-green-500 text-lg ml-2'><FontAwesomeIcon icon={faCheckSquare} /></span>}
                                                    {inq?.caller_status == 2 && <span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faXmarkSquare} /></span>}
                                                    {inq?.caller_status == 3 && <span className='inline-block place-content-center align-top text-blue-500 text-base w-40 ml-2'>در انتظار استعلام</span>}
                                                    {inq?.caller_status == 0 && <span className='inline-block place-content-center align-top text-gray-500 text-base w-40 ml-2'>در انتظار بررسی</span>}
                                                </span>
                                                }
                                                {
                                                    inqData?.code == 300 && <span className='inline-block place-content-center align-top text-gray-500 text-lg ml-2'><FontAwesomeIcon icon={faQuestionCircle} /></span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </> : ''
                        }
                        {/* {tableShow ? <>
                            <p className=' px-8'>
                                <span className="inline-block ml-1">برای مشاهده جزئیات هر اکانت بر روی گزینه مورد نظر کلیک نمایید.</span>
                                {
                                    inqData?.code == 300 ? <span className=" bg-green-200 inline-block p-2 w-fit mx-auto text-center text-sm rounded mt-8 transition-all duration-200 ease-in select-none" style={subAlert ? { "visibility": "visible", "opacity": 1 } : { "visibility": "hidden", "opacity": 0 }}>ورودی از قبل در سیستم وجود نداشت و اضافه شد.</span> : ''
                                }
                            </p>
                            <div className="flex lg:w-1/2 mx-auto mt-4 flex-col justify-center bg-gray-200 shadow-sm shadow-middle-green rounded">
                                <div className="flex justify-between items-center bg-middle-green text-white border-b border-b-gray-400 rounded-t p-2">
                                    <div className="flex items-center"><span className="">شماره: {numInp}</span></div>
                                </div>
                                <div onClick={() => { reportingDetails(); setSocailCh("insta"); setContactImg(contactImginsta) }} className="flex justify-between items-center border-b border-b-gray-400 p-1 cursor-pointer hover:bg-gray-300 transition-all duration-100 ease-in">
                                    <div className="flex items-center mr-4"><span className='inline-block place-content-center align-top fill-gradient-instagram text-white text-lg ml-2'><FontAwesomeIcon icon={faInstagram} /></span><span className="">اینستاگرام</span></div>
                                    <div className="flex items-center w-16 justify-center">
                                        {inqData?.code == 200 && <span>
                                            {inq?.status == 3 && <span className='inline-block place-content-center align-top text-green-500 text-lg ml-2'><FontAwesomeIcon icon={faCheckSquare} /></span>}
                                            {inq?.status == 2 && <span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faXmarkSquare} /></span>}
                                            {inq?.status == 1 && <span className='inline-block place-content-center align-top text-blue-500 text-base w-40 ml-2'>در انتظار استعلام</span>}
                                            {inq?.status == 0 && <span className='inline-block place-content-center align-top text-gray-500 text-base w-40 ml-2'>در انتظار بررسی</span>}
                                        </span>
                                        }
                                        {
                                            inqData?.code == 300 && <span className='inline-block place-content-center align-top text-gray-500 text-lg ml-2'><FontAwesomeIcon icon={faQuestionCircle} /></span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </> : ''} */}
                        {tableShow ? '' : <InqTable />}
                    </div>
            }
        </section>
    )

}

export default InpInq
